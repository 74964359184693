import { Component, OnInit, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
//import { ActivatedRoute, ActivationStart, NavigationEnd, Router } from '@angular/router';
import { ActivatedRoute, ActivationStart, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDrawer, MatSidenav } from '@angular/material/sidenav';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { Spinkit } from 'ng-http-loader';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { NavigationFacade } from '../../../../core/data/navigation/facades/navigation-facade';
import { INavigationNode } from '../../../../core/data/navigation/interfaces/inavigation-node';
import { UserFacade } from '../../../../core/data/user/facades/user-facade';
import { IUserData } from '../../../../core/data/user/interfaces/iuser-data';
import { NotificationFacade } from '../../../../core/data/notification/facades/notification-facade';
import { SidenavService } from '../../../../core/services/sidenav/sidenav.service';
import { ConfigService } from 'src/app/core/config/services/config.service';
import { CacheService } from 'src/app/core/cache/services/cache.service';
import { ImpersonateFacade } from 'src/app/core/data/impersonate/facades/impersonate-facade';
import { BlockuiService } from 'src/app/core/services/blockui/blockui.service';
import { componentsInSidenav, SidenavComponentService } from 'src/app/core/services/sidenav-component/sidenav-component';
import { TitleService } from 'src/app/core/services/title/title.service';
import { SidenavExtendService } from 'src/app/core/services/sidenav-extend/sidenav-extend.service';
import { onMainContentChange, onSideNavChange } from 'src/app/ui/animations/animations';
import { ChatbotFacade } from 'src/app/core/data/chatbot/facades/chatbot-facade';
declare var $: any;
declare var WebChat: any;
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HIDE_SIDEBAR, HIDE_SIDEBAR_TIME, REQUESTED_URL_QUERIES } from 'src/app/core/session/services/auth/auth.service';
import { AES, enc } from "crypto-js";
//import { GoogleTagManagerService } from 'angular-google-tag-manager';

@UntilDestroy()
@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [onMainContentChange, onSideNavChange]
})
export class SiteLayoutComponent implements OnInit, AfterViewInit {

  @BlockUI() blockUI: NgBlockUI;

  public spinkit = Spinkit;
  /**
   * Title to show
   * @type {string}
   */
  title: string = '';

  /**
   * Icons list
   * @type {{any: IconDefinition}}
   */
  icons = {
    user: faUserCircle,
  };

  isInitialized = false;

  user$: Observable<IUserData> = this.userFacade.profile$;

  isImpersonated$: Observable<boolean> = this.impersonateFacade.isActive$;

  sidenavNodes$: Observable<INavigationNode[]> = this.navigationFacade.tree$
    .pipe(map(nodes => nodes.main));

  sidenavNodes: INavigationNode[] = [];

  isHandset$: Observable<boolean> =
    this.breakpointObserver.observe(
      [Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.TabletPortrait,
      Breakpoints.HandsetPortrait,
      Breakpoints.Handset])
      .pipe(map(result => result.matches));

  currentNode$: Observable<INavigationNode> = this.navigationFacade.current$;

  isTutor: boolean = false;

  notificationCenterIsOpen$: Observable<boolean> = this.notificationFacade.containerIsActive$;

  @ViewChild('drawer') drawer: MatDrawer;
  @ViewChild('drawer') sidenav: MatSidenav;
  @ViewChild('notificationCenter') notificationCenter: MatDrawer;

  componentsInSidenav = componentsInSidenav;
  componentSelected = 1;

  extend$ = this.sidenavExtendService.extend$;
  profile: IUserData;

  chatbotId: string;


  display = false;
  loadingChatbot = false;
  conversationStarted = false;

  _enrollment = '';

  _botId = 'af1d30fb-14e5-4c81-bb8d-8a90175c2b24';

  _sharepointInstance = 'https://laureatelatammx.sharepoint.com/';
  _pvaConnection = 'https://powerva.microsoft.com/api/botmanagement/v1/directline/directlinetoken?botId=';
  _botAvatar = 'https://recursoscaeuvm.blob.core.windows.net/contenidopublic/chatbotunitec/iconoschatbot/alan.jpg';

  mustCloseSidebar = false;

  constructor(
    private impersonateFacade: ImpersonateFacade,
    private navigationFacade: NavigationFacade,
    private notificationFacade: NotificationFacade,
    private breakpointObserver: BreakpointObserver,
    private userFacade: UserFacade,
    private router: Router,
    private sidenavService: SidenavService,
    private blockuiService: BlockuiService,
    protected config: ConfigService,
    protected cacheService: CacheService,
    private sidenavComponentService: SidenavComponentService,
    private titleService: TitleService,
    private sidenavExtendService: SidenavExtendService,
    private chatbotFacade: ChatbotFacade//,
    //private gtmService: GoogleTagManagerService,
  ) {
    this.chatbotId = this.config.get('chatbot.chatbotId');
    this.userFacade.profile$.pipe(untilDestroyed(this)).subscribe(profile => {
      this.profile = profile;
    });
    this.navigationFacade.tree$
      .pipe(map(nodes => nodes.main)).subscribe(nodes => this.sidenavNodes = nodes);
  }

  ngOnInit() {

    // this.router.events.forEach(item => {
    //   if (item instanceof NavigationEnd) {
    //     console.log('gtmService: ', item.url);
    //     const gtmTag = {
    //       event: 'page',
    //       pageName: item.url
    //     };
    //     this.gtmService.pushTag(gtmTag);
    //   }
    // });
    this.router.events
      .pipe(filter(event => event instanceof ActivationStart))
      .pipe(untilDestroyed(this)).subscribe((route: any) => {
        this.title = route.snapshot?.data?.title || '';
      });

    this.titleService.getTitle().pipe(untilDestroyed(this)).subscribe(title => {
      this.title = title;
    });
    this.autoClose();

    this.user$.pipe(untilDestroyed(this)).subscribe(s => this.isTutor = s.isTutor);

    this.sidenavComponentService.getComponet().pipe(untilDestroyed(this)).subscribe(componentId => {
      this.componentSelected = componentId;
    });
    this.checkQueries();
  }

  ngAfterViewInit() {
    setTimeout(() => this.isInitialized = true, 10);
    this.sidenavService.setSidenav(this.sidenav);
    this.blockuiService.setBlockUI(this.blockUI);
    this.userFacade.profile$.subscribe(profile => {
      this.profile = profile;
      this.loadChatbotStep(this.chatbotId);
    });
  }

  checkQueries() {
    //debugger;
    let currentDate = (new Date()).getTime();
    let hideSidebar = window.localStorage.getItem(HIDE_SIDEBAR);
    let sidebarTime = window.localStorage.getItem(HIDE_SIDEBAR_TIME);
    if (hideSidebar != null && sidebarTime != null) {
      let hs = Boolean(hideSidebar);
      let hst = Number(sidebarTime);
      if (hs) {
        this.mustCloseSidebar = hst > (currentDate - 600000);
        if (this.mustCloseSidebar) {
          //console.log('setItem 1');
          window.localStorage.setItem(HIDE_SIDEBAR, `${this.mustCloseSidebar}`);
          window.localStorage.setItem(HIDE_SIDEBAR_TIME, `${hst}`);
          return;
        } else {
          localStorage.removeItem(HIDE_SIDEBAR);
          localStorage.removeItem(HIDE_SIDEBAR_TIME);
        }
      }
    }
    //console.log('checkQueries INIT');
    let queries = window.localStorage.getItem(REQUESTED_URL_QUERIES);
    //console.log('checkQueries queries', queries);
    if (queries && queries != 'none') {
      let params = queries.split('vk=');
      //console.log('checkQueries params', params);
      let key = this.config.get('cjs.token', '');
      if (!!key) {
        //console.log('checkQueries key', key);
        let time = 0;
        for (let i = 0; i < params.length; i++) {
          if (params[i] == 'v') {
            try {
              //console.log('checkQueries ' + params[i + 1]);
              let bytes = AES.decrypt(`${params[i + 1]}`, key);
              //console.log('checkQueries ', bytes, bytes.toString(enc.Utf8));
              time = Number(bytes.toString(enc.Utf8));
            }
            catch (e) {
              console.log('CRYPTO ERROR', e);
              time = 0;
            }
            //console.log('checkQueries time', time);
            break;
          }
        };
        if (time > 0) {
          //console.log('checkQueries currentDate', currentDate, time > (currentDate - 600000));
          this.mustCloseSidebar = time > (currentDate - 600000);
          //console.log('setItem 2');
          window.localStorage.setItem(HIDE_SIDEBAR, `${this.mustCloseSidebar}`);
          window.localStorage.setItem(HIDE_SIDEBAR_TIME, `${time}`);
          window.localStorage.removeItem(REQUESTED_URL_QUERIES);
        } else {
          localStorage.removeItem(HIDE_SIDEBAR);
          localStorage.removeItem(HIDE_SIDEBAR_TIME);
        }
      } else {
        localStorage.removeItem(HIDE_SIDEBAR);
        localStorage.removeItem(HIDE_SIDEBAR_TIME);
      }
    } else {
      localStorage.removeItem(HIDE_SIDEBAR);
      localStorage.removeItem(HIDE_SIDEBAR_TIME);
    }
  }

  autoClose() {
    combineLatest(this.currentNode$, this.isHandset$)
      .pipe(untilDestroyed(this)).subscribe(([current, isHandset]) => {
        if (this.sidenav) {
          isHandset ? this.sidenav.close() : null;
        }
      });
  }

  onBackdropClick() {
    this.notificationFacade.closeContainer();
  }

  loadChatbotStep(chatbotId: string = 'af1d30fb-14e5-4c81-bb8d-8a90175c2b24') {
    this.chatbotFacade.active$.pipe(
      filter(m => m),
      distinctUntilChanged()
    ).pipe(untilDestroyed(this)).subscribe(() => {
      this.loadChatbot(chatbotId)
    });
  }

  loadChatbot(chatbotId: string) {
    window.localStorage.setItem('matricula', this.profile.sisAccount);
    this.InitializeComponents(chatbotId);

    document.getElementById('chatbot-floating-button').addEventListener('click', () => {
      console.log('chatbot clicked');
      this.ShowChatbot();
      if (!this.conversationStarted && !this.loadingChatbot) {
        this.ConfigureChatBot(this.chatbotId);
      }

    });
  }

  InitializeComponents(chatbotId: string) {
    this.LoadChatBot(chatbotId);
  }

  LoadChatBot(chatbotId: string) {
    var chatbotId = chatbotId;
    //this.ShowLoaderChatBot(true);
    //this.ConfigureChatBot(chatbotId);
  }


  ShowLoaderChatBot(show) {
    if (show) {
      $("#webchat-container").append(`
                <div class="loader-container">
                    <div class="loader ring">
                        <div class="ring-child"></div>
                        <div class="ring-child"></div>
                        <div class="ring-child"></div>
                        <div class="ring-child"></div>
                        <p class="loader-text">Cargando ChatBot...</p>
                    </div>
                </div>
            `);
    } else {
      $("#webchat-container").children(".loader-container").remove();
      $("#webchat").show();
    }
  }

  ConfigureChatBot(BOT_ID) {
    this.loadingChatbot = true;
    const styleOptions = {
      // Avatar styles options
      avatarBorderRadius: '50%',
      avatarSize: 45,
      botAvatarBackgroundColor: "#ffffff",
      botAvatarImage: this._botAvatar,
      botAvatarInitials: '',
      userAvatarBackgroundColor: '#FFFFFF',
      userAvatarImage: './utils/img/student-logo.png',
      userAvatarInitials: '',
      showAvatarInGroup: 'status',
      // Add styleOptions to customize web chat canvas
      hideUploadButton: true,
      fontFamily: "'Comic Sans MS', 'Arial', 'sans-serif'",
      fontWeight: "bold",
      backgroundColor: 'White',
      // TODO: Should we make a bubbleFromBot
      bubbleBackground: '#d5d5d5',
      bubbleBorderColor: '#E6E6E6',
      bubbleBorderRadius: 8,
      bubbleBorderStyle: 'solid',
      bubbleBorderWidth: 1,
      bubbleFromUserBackground: 'White',
      bubbleFromUserBorderColor: '#E6E6E6',
      bubbleFromUserBorderRadius: 8,
      bubbleFromUserBorderStyle: 'solid',
      bubbleFromUserBorderWidth: 1,
      bubbleFromUserNubOffset: 0,
      bubbleFromUserNubSize: undefined,
      bubbleFromUserTextColor: 'Black',
      bubbleImageHeight: 240,
      bubbleMaxWidth: 480,
      bubbleMinHeight: 40,
      bubbleMinWidth: 250,
      bubbleNubOffset: 0,
      bubbleNubSize: undefined,
      bubbleTextColor: 'Black',
      messageActivityWordBreak: 'break-word',
      // Suggested actions
      suggestedActionBackgroundColor: 'White',
      suggestedActionBorderColor: '#0d6fb8',
      suggestedActionBorderRadius: 8,
      suggestedActionBorderStyle: 'solid',
      suggestedActionBorderWidth: 2,
      suggestedActionBackgroundDisabled: undefined,
      suggestedActionBorderColorDisabled: '#E6E6E6',
      suggestedActionBorderStyleDisabled: 'solid',
      suggestedActionBorderWidthDisabled: 2,
      suggestedActionTextColorDisabled: undefined,
      suggestedActionImageHeight: 20,
      suggestedActionLayout: 'carousel',
      suggestedActionTextColor: '#0d6fb8',
      // Suggested actions carousel layout
      suggestedActionsCarouselFlipperCursor: undefined,
      suggestedActionsCarouselFlipperBoxWidth: 40,
      suggestedActionsCarouselFlipperSize: 20,
      // Suggested actions flow layout
      suggestedActionsFlowMaxHeight: undefined,
      // Suggested actions stacked layout
      suggestedActionsStackedHeight: undefined,
      suggestedActionsStackedOverflow: undefined,
      suggestedActionsStackedLayoutButtonMaxHeight: undefined,
      suggestedActionsStackedLayoutButtonTextWrap: false,
    };
    const store = WebChat.createStore(
      {},
      ({ dispatch }) => next => action => {
        if (action.type === "DIRECT_LINE/CONNECT_FULFILLED") {
          new Promise(function (resolve) {
            dispatch({
              type: 'WEB_CHAT/SEND_EVENT',
              payload: {
                name: 'pvaSetContext',
                value: { "Matricula": `${window.localStorage.getItem('matricula')}` }
              }
            })
            setTimeout(() => resolve(1), 1000);
          }).then(function (result) {
            dispatch({
              meta: {
                method: "keyboard"
              },
              payload: {
                activity: {
                  channelData: {
                    postBack: true
                  },
                  name: "startConversation",
                  type: "event"
                }
              },
              type: "DIRECT_LINE/POST_ACTIVITY"
            });
          });
        }
        if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
          const activity = action.payload.activity;
          if (activity.from && activity.from.role === 'bot') {
            this.MaskLink();
            this.ShowLoaderChatBot(false);
          }
        }
        return next(action);
      }
    );
    fetch(`${this._pvaConnection}${BOT_ID}`)
      .then(response => response.json())
      .then(conversationInfo => {
        WebChat.renderWebChat(
          {
            directLine: WebChat.createDirectLine({
              token: conversationInfo.token,
            }),
            store: store,
            styleOptions: styleOptions,
          },
          document.getElementById("webchat"),
          this.conversationStarted = true
        );
      });
  }

  MaskLink() {
    $(".markdown").children("p").children("a").each((index, element) => {
      const href = $(element).attr("href");
      const text = $(element).text();
      if ($(element).hasClass("masked-link"))
        return;
      if (!href.startsWith(this._sharepointInstance))
        return;
      if (href !== text && href != encodeURI(text))
        return;
      const image = $(element).children("img");
      $(element).addClass("masked-link");
      $(element).text("Da clic para consultar la información");
      $(element).append(image);
    });
  }

  ShowChatbot() {
    if (this.display) {
      $("#webchat-container").hide();
    } else {
      $("#webchat-container").show();
    }
    this.display = !this.display;
  }
}
