import { createAction, props } from '@ngrx/store';
import { IAlternativeModel, IConfirmPaymentResponse } from 'src/app/core/interfaces/enrollment/ienrollment';
import { IAlternativesAvailable } from '../interfaces/ireenrollment-status';

export const SET_TOKEN_DATA = createAction(
  '[REENROLLMENT] set SET_TOKEN_DATA',
  props<{ payload: any }>()
);

export const SET_KEY_CEMDI_DATA = createAction(
  '[REENROLLMENT] set SET_KEY_CEMDI_DATA',
  props<{ payload: any }>()
);

export const SET_STUDENT_DATA = createAction(
  '[REENROLLMENT] set STUDENT_DATA data',
  props<{ payload: any }>()
);

export const SET_SUBJECTS_DATA = createAction(
  '[REENROLLMENT] set SUBJECTS_DATA data',
  props<{ payload: any }>()
);

export const SET_ALTERNATIVE_DATA = createAction(
  '[REENROLLMENT] set ALTERNATIVE_DATA data',
  props<{ payload: IAlternativesAvailable }>()
);

export const SET_PAYMENT_PROOF_DATA = createAction(
  '[REENROLLMENT] set PAYMENT_PROOF data',
  props<{ payload: IConfirmPaymentResponse }>()
);

export const SET_PAYMENT_RESUME_DATA = createAction(
  '[REENROLLMENT] set PAYMENT_RESUME_DATA data',
  props<{ payload: any }>()
);

export const SET_PAYMENT_DATA = createAction(
  '[REENROLLMENT] set PAYMENT_DATA data',
  props<{ payload: any }>()
);

export const REMOVE_DATA = createAction(
  '[REENROLLMENT] REMOVE DATA data',
  props<{ payload: boolean }>()
);
