import { Component, Input, OnInit, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { INavigationNode } from '../../../../core/data/navigation/interfaces/inavigation-node';
import { SidenavExtendService } from 'src/app/core/services/sidenav-extend/sidenav-extend.service';
import { Observable, of } from 'rxjs';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { map, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActionType, INavigationNodeData, INodeTimer } from 'src/app/core/data/navigation/interfaces/inavigation-node-data';
import { NavigationFacade } from 'src/app/core/data/navigation/facades/navigation-facade';
import * as _ from 'lodash';
import { INavigationTree } from 'src/app/core/data/navigation/interfaces/inavigation-tree';
import { UserFacade } from 'src/app/core/data/user/facades/user-facade';
import { IUserData } from 'src/app/core/data/user/interfaces/iuser-data';

@UntilDestroy()
@Component({
  selector: 'app-nav-list',
  templateUrl: './nav-list.component.html',
  styleUrls: ['./nav-list.component.scss'],
})
export class NavListComponent implements OnInit, AfterViewInit, OnChanges {

  @Input('nodes') nodes: INavigationNode[];

  extend$ = this.sidenavExtendService.extend$;

  activeNodes: INavigationNode[];
  hidenNodes: INavigationNode[];

  isHandset$: Observable<boolean> =
    this.breakpointObserver.observe(
      [Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.TabletPortrait,
      Breakpoints.HandsetPortrait,
      Breakpoints.Handset])
      .pipe(map(result => result.matches));

  isMobile: Observable<boolean>;
  nodesEtt: INodeTimer[] = [];
  profile: IUserData;

  public constructor(
    private sidenavExtendService: SidenavExtendService,
    private breakpointObserver: BreakpointObserver,
    private navigationFacade: NavigationFacade,
    private userFacade: UserFacade
  ) {
    this.userFacade.profile$.subscribe(profile => this.profile = profile);
  }

  ngOnInit() {

  }

  ngAfterViewInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('ngOnChanges', changes);
    this.activeNodes = this.nodes.filter(node => node.action != ActionType.hide);
    this.hidenNodes = this.nodes.filter(node => node.action == ActionType.hide);
    console.log('NavListComponent ', this.activeNodes, this.hidenNodes);
    this.middlewareHidenNodes();
  }

  middlewareHidenNodes() {
    console.log('middlewareHidenNodes', this.hidenNodes);
    this.hidenNodes.forEach(node => {
      this.handleNodeSchedule(node);
    });
  }

  handleNodeSchedule(node: INavigationNode) {
    console.log('handleNodeSchedule', node);
    if (node.hasNextAction()) {
      if (node.getScheduledEndTime()) {
        let time = node.getScheduledEndTime().split(':');
        let currentDate = new Date();
        let endTime = new Date();
        endTime.setHours(Number(time[0]), Number(time[1]), Number(time[2]));
        if (endTime.getTime() <= currentDate.getTime()) {
          //console.log('NO SE APLICA REGLA');
          return;
        }
        let executeTime = endTime.getTime();
        let currentTime = (new Date()).getTime();
        //console.log(`menu ${this.node.id} should ${this.actualAction} in ${executeTime} from ${currentTime} so in ${(executeTime - currentTime) / 1000} `);
        let tidindex = this.nodesEtt.findIndex(n => n.id == node.id);
        if (tidindex > 1) {
          clearTimeout(this.nodesEtt[tidindex].tid);
          this.nodesEtt.splice(tidindex, 1);
        }

        let timeToExecute = executeTime - currentTime;
        console.log(`will execute executeNextStatus in ${timeToExecute / 1000} seconds `, node);
        let currentTTNode = <INodeTimer>{
          id: node.id
        }
        currentTTNode.tid = setTimeout(() => {
          this.executeNextStatus(node);
        }, timeToExecute);
      }
    }
  }

  executeNextStatus(node: INavigationNode) {
    console.log('executeNextStatus', node);
    this.navigationFacade.configState$.pipe(take(1)).subscribe((navigationNodes: INavigationTree<INavigationNodeData>) => {
      this.changeNextStatus(_.cloneDeep(navigationNodes), node);
    })
  }

  changeNextStatus(navigationNodes: INavigationTree<INavigationNodeData>, node: INavigationNode) {
    console.log('changeNextStatus ', navigationNodes, node);
    let currentNavigation: INavigationTree<INavigationNodeData> = JSON.parse(JSON.stringify(navigationNodes))
    if (node.hasNextAction()) {
      if (currentNavigation.main.find(n => n.id == node.id) != undefined) {
        currentNavigation.main.find(n => n.id == node.id).action = node.getNextAction();
        currentNavigation.main.find(n => n.id == node.id).data = node.getNextActionData();
      }
      else {
        let parentId = this.getParentId(node.id);
        currentNavigation.main.find(n => n.id == parentId).children.find(nd => nd.id == node.id).action = node.getNextAction();
        currentNavigation.main.find(n => n.id == parentId).children.find(nd => nd.id == node.id).data = node.getNextActionData();
      }
      console.log('node update', currentNavigation);
      this.navigationFacade.setConfig(currentNavigation);
      let tidindex = this.nodesEtt.findIndex(n => n.id == node.id);
      if (tidindex > 1) {
        clearTimeout(this.nodesEtt[tidindex].tid);
        this.nodesEtt.splice(tidindex, 1);
      }
      let menuSub = setTimeout(() => {
        this.navigationFacade.restoreNavigation(this.profile.id);
        clearTimeout(menuSub);
        menuSub = null;
      }, 30000)
    }
  }

  getParentId(id: string) {
    let idSplited = id.split('.');
    idSplited.pop();
    return idSplited.join('.');
  }

  toggle() {
    this.sidenavExtendService.toggle();
  }

}
