import { Component, OnInit } from '@angular/core';
import { ImpersonateFacade } from '../../../../core/data/impersonate/facades/impersonate-facade';
import { UserFacade } from '../../../../core/data/user/facades/user-facade';
import { ConfigService } from '../../../../core/config/services/config.service';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { DomSanitizer } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TitleRequestFacade } from 'src/app/core/data/title-request/facades/title-request-facade';
import { PROFILE_URL, REQUESTED_URL } from 'src/app/core/session/services/auth/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-impersonate-banner',
  templateUrl: './impersonate-banner.component.html',
  styleUrls: ['./impersonate-banner.component.scss']
})
export class ImpersonateBannerComponent implements OnInit {
  protected sselUrl: string;
  protected sselUrlSnt: any;
  closeSSELSession: boolean = false;

  profile$ = this.userFacade.profile$;
  isActive$ = this.impersonateFacade.isActive$;
  constructor(
    private userFacade: UserFacade,
    private sanitizer: DomSanitizer,
    private impersonateFacade: ImpersonateFacade,
    private config: ConfigService,
    private spinner: SpinnerVisibilityService,
    private titlesFacade: TitleRequestFacade
  ) {
      //this.sselUrl = `${this.config.get('ssel.url')}${this.config.get('ssel.legacyLogin')}`;

      //this.sselUrlSnt = this.sanitizer.bypassSecurityTrustResourceUrl(this.sselUrl);
    }

  ngOnInit() {
  }

    getUrl() {
      return this.sselUrlSnt;
    }

    quit() {
      this.spinner.show();
      localStorage.removeItem(REQUESTED_URL);
      this.titlesFacade.clean(true);
      this.closeSSELSession = true;

      setTimeout(() => {
        this.spinner.hide();
        this.impersonateFacade.restore();
      }, 3000);
    }
}
