import { Injectable } from '@angular/core';
import { AppHttp } from '../../../core/http/services/app-http/app-http.service';
import { EndpointsService } from '../../../core/http/services/endpoints/endpoints.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { xml2json } from 'xml2json-light';
import { ConfigService } from '../../../core/config/services/config.service';
import { Endpoint } from 'src/app/core/http/models/endpoint/endpoint';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {

  carouselEndpoint: Endpoint;

  constructor(private http: AppHttp,
    private endpoints: EndpointsService,
    protected config: ConfigService,
    private httpClient: HttpClient) {
    this.carouselEndpoint = this.endpoints.assets.carousel;
  }

  /**
   * Return a list of images to display in carousel
   * Endpoint response XML but is parsed to json object
   * @returns {Observable<any>}
   */
  getCarousel() {
    this.carouselEndpoint.useWildcardParams({ file: 'images.json' });
    return this.http.get(this.endpoints.assets.carousel)
      .pipe(map(response => response));
  }

  getCarouselEndpoint(): Endpoint {
    return this.endpoints.assets.carousel;
  }

  getWelcomeMessage() {
    return this.http.get(this.endpoints.assets.message, {
      responseType: 'text'
    });
  }

  getWelcomeImageURL(): string {
    return this.config.get('storage.blob.url', '') + '/assets/welcome.jpg?'+ this.config.get('storage.blob.signature');
  }

  getMfaMessage() {
    return this.http.get(this.endpoints.assets.mfaMessage, {
      responseType: 'text'
    });
  }

  getBookSaleMessage() {
    return this.http.get(this.endpoints.assets.bookSaleMessage, {
      responseType: 'text'
    });
  }

  getMfaImageURL(): string {
    return this.config.get('storage.blob.url', '') + '/assets/mfa.png';
  }

  getMfaImageBlob() {
    return this.httpClient.get(this.config.get('storage.blob.url', '') + '/assets/mfa.png', {
      responseType: 'blob'
    }).pipe(
      map(data => data));
  }

  getStorageFile(path: string) {
    let url = path.includes('https://') ? path : this.config.get('storage.blob.url') + path;
    return from(fetch(url));
  }
}
