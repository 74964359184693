import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FaConfig, FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import { AssetsService } from './services/assets/assets.service';
import { IconComponent } from './components/icon/icon.component';
import { NgxExtendedPdfViewerVVModule } from './../shared/ngx-extended-pdf-viewer-vv/src/public_api';
import { NgxExtendedPdfViewerNoprintComponent } from './components/ngx-extended-pdf-viewer-noprint/ngx-extended-pdf-viewer-noprint.component';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { DocumentViewerModule } from './document-viewer/document-viewer.module';
import { MaterialFileInputModule } from './ngx-material-file-input/material-file-input.module';
//import { EditorModule } from '@tinymce/tinymce-angular';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { PrimeModule } from './primeng/prime.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FontAwesomeModule,
    SlickCarouselModule,
    NgHttpLoaderModule.forRoot(),
    ReactiveFormsModule,
    NgxExtendedPdfViewerVVModule,
    NgxCaptchaModule,
    NgxExtendedPdfViewerModule,
    DocumentViewerModule,
    //EditorModule,
    MaterialFileInputModule,
    NgScrollbarModule,
    PrimeModule
  ],
  declarations: [
    IconComponent,
    NgxExtendedPdfViewerNoprintComponent,
  ],
  exports: [
    MaterialModule,
    FlexLayoutModule,
    FontAwesomeModule,
    SlickCarouselModule,
    NgHttpLoaderModule,
    ReactiveFormsModule,
    NgxExtendedPdfViewerVVModule,
    NgxCaptchaModule,
    //EditorModule,
    NgxExtendedPdfViewerModule,
    NgxExtendedPdfViewerNoprintComponent,
    IconComponent,
    DocumentViewerModule,
    MaterialFileInputModule,
    NgScrollbarModule,
    PrimeModule
  ],
  providers: [
    AssetsService,
  ]
})
export class UIModule {
  constructor(
    library: FaIconLibrary,
    faConfig: FaConfig
  ) {
    library.addIconPacks(fas, fab, far);
    faConfig.defaultPrefix = 'fas';
    faConfig.fixedWidth = true;
  }
}
