import { createReducer, on } from '@ngrx/store';
import { IReenrollmentState } from '../interfaces/ireenrollment-status';
import {
  REMOVE_DATA,
  SET_ALTERNATIVE_DATA,
  SET_KEY_CEMDI_DATA,
  SET_PAYMENT_DATA,
  SET_PAYMENT_PROOF_DATA,
  SET_PAYMENT_RESUME_DATA,
  SET_STUDENT_DATA,
  SET_SUBJECTS_DATA,
  SET_TOKEN_DATA,
} from './reenrollment.actions';
import { REENROLLMENT_STATE } from './reenrollment.state';

const _reenrollmentReducer = createReducer(
  REENROLLMENT_STATE,
  on(SET_TOKEN_DATA, (state: IReenrollmentState, { payload }) => ({
    ...state,
    tokenData: payload,
  })),
  on(SET_KEY_CEMDI_DATA, (state: IReenrollmentState, { payload }) => ({
    ...state,
    apiKeyCemdi: payload,
  })),
  on(SET_STUDENT_DATA, (state: IReenrollmentState, { payload }) => ({
    ...state,
    studentData: payload,
  })),
  on(SET_SUBJECTS_DATA, (state: IReenrollmentState, { payload }) => ({
    ...state,
    subjectsData: payload,
  })),
  on(SET_PAYMENT_RESUME_DATA, (state: IReenrollmentState, { payload }) => ({
    ...state,
    paymentResumeData: payload,
  })),
  on(SET_PAYMENT_DATA, (state: IReenrollmentState, { payload }) => ({
    ...state,
    paymentResumeData: payload,
  })),
  on(SET_ALTERNATIVE_DATA, (state: IReenrollmentState, { payload }) => ({
    ...state,
    alternatives: payload
  })),
  on(SET_PAYMENT_PROOF_DATA, (state: IReenrollmentState, { payload }) => ({
    ...state,
    paymentProof: payload
  })),
on(REMOVE_DATA, (state: IReenrollmentState, { payload }) => ({
    ...REENROLLMENT_STATE,
    active: false,
    tokenData: null,
    studentData: null
  }))
);

export function ReenrollmentReducer(state, action) {
  return _reenrollmentReducer(state, action);
}
