import { IConfirmPaymentResponse } from 'src/app/core/interfaces/enrollment/ienrollment';
import { IAlternativesAvailable, IReenrollmentState } from '../interfaces/ireenrollment-status';

export const REENROLLMENT_STATE: IReenrollmentState = {
    tokenData: '',
    apiKeyCemdi: '',
    paymentData: {},
    paymentResumeData: {},
    studentData: {},
    subjectsData: {},
    active: false,
    alternatives: <IAlternativesAvailable>{},
    paymentProof: <IConfirmPaymentResponse>{}
};
