import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidenavExtendService {

  private extendSubject = new BehaviorSubject<boolean>(true);
  private isOpen = true;

  extend$ = this.extendSubject.asObservable();

  constructor() {
    this.next();
  }

  private next() {
    this.extendSubject.next(this.isOpen);
  }

  public extend() {
    this.isOpen = true;
    this.next();
  }

  public contract() {
    this.isOpen = false;
    this.next();
  }

  public toggle() {
    this.isOpen = !this.isOpen;
    this.next();
  }

}
